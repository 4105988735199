
// To add adobe analytics tagging to a teaser card,
// 1. Call this function in `onMount()` of the teaser card
// 3. Add `data-adobename` and `data-adobeinfo` attributes to the `<a>` tag
//    you want to track. These can use data from `dataForRender()`
function initAdobeAnalytics(container) {
  const tracked_els = container.querySelectorAll('[data-adobename][data-adobeinfo]');
  Array.from(tracked_els).forEach(el => {
    el.addEventListener('click', e => {
      const eventName = el.dataset.adobename;
      const eventInfo = JSON.parse(el.dataset.adobeinfo || '');
      if (!eventName || !eventInfo) { return; }
      window.adobeDataLayer = window.adobeDataLayer || [];
      window.adobeDataLayer.push({
        event: eventName,
        eventInfo: eventInfo,
      });
    });
  });
}

export {
  initAdobeAnalytics,
}
